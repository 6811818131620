#store {
  width: 100%;
  height: 37.6vw;
  background: url("../../assets/imgs/bg/store/2x.png") 0 0 no-repeat padding-box;
  background-size: cover;
  display: flex;
  align-items: center;
}

#store h1 {
  color: #fff;
}

#store .btnsBox {
  margin: 2.5rem 0;
}

#store .btnsBox button.store {
  background-color: transparent;
  margin: 0 1rem 0 0;
}

#store .btnsBox button.store img {
  width: 12rem;
  height: 3.5rem;
}

/* =============================================== */
/*                 루튼 폰트 사이즈 변경 */
/* =============================================== */

/* 피씨 */
@media screen and (max-width: 1199px) {
  #store {
    height: 70vw;
  }

  #store h1 {
    text-align: center;
  }

  #store .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* 테블릿 */
@media screen and (max-width: 899px) {
}

/* 모바일 */
@media screen and (max-width: 599px) {
}

@media screen and (max-width: 479px) {
}
