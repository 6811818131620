#review {
  padding: 6rem 0 !important;
  background-color: #5b64d1 !important;
}

#review .container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#review h1 {
  color: #fff;
  font-weight: bold;
}

#review .imgContainer {
  overflow: scroll;
  width: 100vw;
  display: flex;
  justify-content: center;
}
::-webkit-scrollbar {
  display: none;
}
#review .imgBox {
  display: flex;
  align-items: center;
  justify-self: center;
  margin: 5rem 0 0 0;
}

#review .imgBox .img {
  width: 22rem;
  height: 30.65rem;
  object-fit: cover;
  margin: 0 1rem;
}

/* =============================================== */
/*                 루튼 폰트 사이즈 변경 */
/* =============================================== */

/* 피씨 */
@media screen and (max-width: 1199px) {
  #review .imgBox {
    overflow-x: scroll;
  }

  #review .imgBox .img {
    width: 15rem;
    height: 20.89rem;
    object-fit: cover;
    margin: 0 1rem;
  }
}

/* 테블릿 */
@media screen and (max-width: 899px) {
}

/* 모바일 */
@media screen and (max-width: 599px) {
  #review .imgBox .img {
    width: 90vw;
    margin: 0 5vw;
    height: auto;
  }
}

@media screen and (max-width: 479px) {
}
