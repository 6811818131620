footer {
  background: linear-gradient(#5b64d1, #3b3f7e);
  padding: 4rem !important;
}

footer .container {
  display: flex !important;
}

footer .logo {
  width: 9.3rem;
  height: 3rem;
  margin: 0 4rem 0 0;
}

footer p {
  color: #fff;
  line-height: 1.5rem;
}

footer .txtContainer {
  position: relative;
}

footer .txtContainer img {
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  right: -5rem;
  bottom: 0;
}

/* =============================================== */
/*                 루튼 폰트 사이즈 변경 */
/* =============================================== */

/* 피씨 */
@media screen and (max-width: 1199px) {
  footer .container {
    display: flex !important;
    flex-direction: column;
  }
  footer .logo {
    margin: 0 0 3rem 0;
  }

  footer .txtContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  footer .txtContainer img {
    position: static;
  }
}

/* 테블릿 */
@media screen and (max-width: 899px) {
}

/* 모바일 */
@media screen and (max-width: 599px) {
}

@media screen and (max-width: 479px) {
}
