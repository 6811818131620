section.first {
  width: 100%;
  min-height: 100vh;
  background-image: url("../../assets/imgs/bg/header/2x.png");
  background-size: cover;
  display: flex;
  align-items: center;
  color: #fff;
}

section.first .logo {
  width: 9.3rem;
  height: 3rem;
  position: absolute;
  top: 2rem;
  right: 50%;
  z-index: 1;
  transform: translateX(4.15rem);
}

section.first .container {
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

section.first .mean {
  font-size: 1.35rem;
  font-weight: 500;
  margin-bottom: 0.8rem;
  font-weight: bold;
}

section.first h1 {
  font-size: 2.9rem !important;
  font-weight: bold;
}

section.first .subTitle {
  font-size: 1.75rem;
  line-height: 2.5rem;
  margin: 3rem 0 2.5rem 0;
}

section.first span {
  color: #ffdb79;
}

section.first button.store {
  background-color: transparent;
  margin: 0 1rem 0 0;
}

section.first .btnsBox.mo {
  display: none;
}

section.first button.store img {
  width: 12rem;
  height: 3.5rem;
  /* width: 9.72rem;
  height: 2.835rem; */
}

section.first div.appImg_Box {
  width: 27.15rem;
  height: 27.15rem;
  background-image: url("../../assets/imgs/bg/wave/2x.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.first img.app {
  width: 14.2rem;
  height: 28.85rem;
  object-fit: cover;
}

/* 피씨 */
@media screen and (max-width: 1199px) {
  section.first {
    flex-direction: column;
  }
  section.first .container {
    flex-direction: column;
    justify-content: space-around;
  }

  section.first .logo {
    position: static;
    transform: translateX(0);
    margin: 0 0 4rem 0;
  }

  section.first .container .textInfo {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  section.first .subTitle {
    text-align: center;
  }

  section.first .btnsBox {
    display: none;
  }
  section.first .btnsBox.mo {
    display: flex;
    margin: 4rem 0;
  }
  section.first button.store {
    background-color: transparent;
    margin: 0 0.5rem;
  }
}

/* 테블릿 */
@media screen and (max-width: 899px) {
}

/* 모바일 */
@media screen and (max-width: 599px) {
}
