html {
  font-size: 20px !important;
}

* {
  box-sizing: border-box;
}

button {
  padding: 0;
  margin: 0;
  border-width: 0;
  background-color: transparent;
  cursor: pointer;
}

section,
footer {
  min-height: 100%;
  padding: 4rem 10rem !important;
  display: flex !important;
  justify-content: center !important;
  overflow: hidden;
}

section .container,
footer .container {
  max-width: 84rem;
  width: 100%;
  align-self: center;
}

h1 {
  font-size: 2rem !important;
  line-height: 2.5rem !important;
}

h2 {
  font-size: 1.5rem !important;
}

.App {
  position: relative;
}

.popUp {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.popUpBox {
  position: relative;
}
.popUpBox .x {
  width: 1.3rem;
  height: 1.3rem;
}

.popUp img {
  width: 30rem;
  height: 30rem;
  border-radius: 1.5rem;
}

.popUp button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #fff;

  font-size: 1.5rem;
}

/* =============================================== */
/*                 루튼 폰트 사이즈 변경 */
/* =============================================== */

@media screen and (max-width: 1799px) {
  /* 데스크탑 */
  html {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1199px) {
  html {
    font-size: 16px !important;
  }
  section,
  footer {
    padding: 4rem 0rem !important;
  }
}

/* 테블릿 */
@media screen and (max-width: 899px) {
  html {
    font-size: 14px !important;
  }
}

/* 모바일 */
@media screen and (max-width: 599px) {
  html {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 479px) {
  html {
    font-size: 10px !important;
  }
}
