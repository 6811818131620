#advantage {
  background-color: #f7f7f8;
  display: flex;
  flex-direction: column;
  padding: 6rem 0 !important;
}

#advantage .container .headerBox {
  padding: 0 10rem;
}

#advantage .container .headerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#advantage .container .headerBox h1 {
  font-weight: bold;
}

#advantage .container .headerBox .illustration {
  width: 38.85rem;
  height: 24.3rem;
}

#advantage .headerBox {
  display: flex;
}
#advantage .imgContainer {
  /* overflow: scroll; */
  width: 100vw;
  display: flex;
  justify-content: center;
  width: 100%;
}
::-webkit-scrollbar {
  display: none;
}
#advantage .imgBox {
  display: flex;
  align-items: center;
  justify-self: center;
  margin: 5rem 0 0 0;
}

#advantage .imgBox .img {
  width: 22rem;
  height: 30.65rem;
  object-fit: cover;
  margin: 0 1rem;
}

/* =============================================== */
/*                 루튼 폰트 사이즈 변경 */
/* =============================================== */

/* 피씨 */
@media screen and (max-width: 1199px) {
  #advantage .container {
    display: flex;
    flex-direction: column;
  }
  #advantage .headerBox {
    align-self: center;
  }
  #advantage .headerBox h1 {
    text-align: center;
  }
  #advantage .container .headerBox .illustration {
    display: none;
  }

  #advantage .imgBox {
    overflow-x: scroll;
  }

  #advantage .imgBox .img {
    width: 15rem;
    height: 20.89rem;
    object-fit: cover;
    margin: 0 1rem;
  }
}

/* 테블릿 */
@media screen and (max-width: 899px) {
}

/* 모바일 */
@media screen and (max-width: 599px) {
  #advantage .imgBox .img {
    width: 90vw;
    margin: 0 5vw;
    height: auto;
  }
}

@media screen and (max-width: 479px) {
}
