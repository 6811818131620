section#introduce {
  width: 100%;
}

section#introduce .boxsContainer {
  height: 36rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section#introduce .boxsContainer h1 p {
  font-weight: bold;
}

section#introduce .boxsContainer h2 {
  margin: 2rem 0 0 0;
}

section#introduce .imgsBox {
  width: 22rem;
  height: 25rem;
  position: relative;
}

section#introduce .imgsBox img {
  height: 21rem;
  width: 10.8rem;
  position: absolute;
  object-fit: cover;
}

section#introduce .imgsBox img.first {
  bottom: 0;
  left: 0;
}

section#introduce .imgsBox img.second {
  top: 0;
  right: 0;
}

/* =============================================== */
/*                 루튼 폰트 사이즈 변경 */
/* =============================================== */

/* 피씨 */
@media screen and (max-width: 1199px) {
  section#introduce .boxsContainer {
    flex-direction: column;
    align-items: center;
    margin: 5rem 0;
  }

  section#introduce .boxsContainer .txtsBox {
    text-align: center;
  }
}

/* 테블릿 */
@media screen and (max-width: 899px) {
}

/* 모바일 */
@media screen and (max-width: 599px) {
}

@media screen and (max-width: 479px) {
}
